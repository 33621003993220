<template>
  <v-app>
    <v-app-bar color="background" :elevation="0" class="border-b border-0">
      <v-app-bar-nav-icon @click="toggleNavigation"/>
      <v-app-bar-title @click="navigateTo(Routes.home)" v-if="$vuetify.display.mdAndUp">
        {{ $t('app.name') }}
      </v-app-bar-title>
      <v-spacer/>
      <v-btn
          :to="Routes.app.account.settings"
          :active="true"
          variant="plain"
          icon="mdi mdi-cog-outline"
      />
      <v-btn
          :to="Routes.logout"
          variant="plain"
          icon="mdi mdi-logout"
      />
    </v-app-bar>
    <app-navigation-drawer/>
    <v-main class="mt-15 pt-8 mt-md-15 mx-2 mx-md-5">
      <v-container>
        <slot/>
      </v-container>
    </v-main>
  </v-app>
</template>
<script setup lang="ts">
import AppNavigationDrawer from "~/components/app/app-navigation-drawer.vue";
import {Routes, useNavigationStore} from "~/lib/navigation";
import {storeToRefs} from "pinia";
import {useAccountsStore} from "~/lib/accounts";
import {navigateTo, watch} from "#imports";

const navigation = useNavigationStore();
const {displayNavigation} = storeToRefs(navigation);

const toggleNavigation = (): boolean => displayNavigation.value = !displayNavigation.value


const accounts = useAccountsStore();
const {current, user} = storeToRefs(accounts);


watch(user, async (oldUser, nextUser) => {
  console.log('usr!', oldUser, nextUser)
  if (oldUser && !nextUser) {
    console.log('logging you out.');
    await accounts.signOut();
    return;
  }


  await accounts.loadCurrentAccount();
});

watch(current, async (oldAccount, nextAccount) => {
  console.log('current!', oldAccount, nextAccount)
  if (nextAccount) {
    console.log('next account');
    if (!nextAccount.active) {
      await navigateTo(Routes.app.signup.complete);
    }
  }
});

onMounted(async () => {
  await accounts.loadCurrentAccount();

  if (current.value && !current.value.active) {
    return navigateTo(Routes.app.signup.complete)
  }
})

</script>