<template>
  <v-navigation-drawer color="background" class="border-e" v-model="navigation.displayNavigation">
    <template v-slot:default>
      <v-list color="primary-lighten-1">

        <client-only>
          <v-list-item
              v-if="currentBillingAccount"
              class="mt-2"
              :title="currentAccountName"
              :subtitle="$t('app.billing.credits-remaining', {credits: $n(currentBalance, 'currency')})"
              @click="navigateTo(Routes.app.billing.home)"
          />
        </client-only>
        <v-list-item density="compact" class="my-2">
         <client-only>
           <nuxt-link class="on-surface" :to="Routes.app.billing.topUp">
             Top up balance
           </nuxt-link>
         </client-only>
        </v-list-item>
        <v-divider class="mb-3"/>
        <v-list-item
            :title="$t('app.nav.dashboard')"
            :to="Routes.app.home"
        />
        <v-list-item
            :title="$t('app.nav.messages')"
            :to="Routes.app.messages.home"
        />
        <v-list-item
            :title="$t('app.nav.contacts')"
            :to="Routes.app.contacts.home"
        />
        <v-list-item
            :title="$t('app.nav.sender-ids')"
            :to="Routes.app.senderIds.home"
        />
        <v-list-item
            :title="$t('app.nav.billing')"
            :to="Routes.app.billing.home"
        />
      </v-list>
    </template>
    <template v-slot:append>
      <client-only>
        <v-divider/>
        <v-row class="pa-2">
          <v-col cols="12">
            <v-btn
                variant="plain"
                :text="$t('app.nav.settings')"
                :to="Routes.app.account.settings"
                :block="true"
            />
          </v-col>
        </v-row>
      </client-only>
    </template>
  </v-navigation-drawer>
</template>
<script lang="ts" setup>
import {Routes, useNavigationStore} from "~/lib/navigation";
import {type BillingAccount, useAccountsStore} from "~/lib/accounts";
import {storeToRefs} from "pinia";

const navigation = useNavigationStore();
const accounts = useAccountsStore();

const {current, user} = storeToRefs(accounts);

const currentBillingAccount = computed(
    (): BillingAccount | undefined => current.value && current.value.billing_accounts.length > 0
        ? current.value.billing_accounts[0]
        : undefined,
);

const currentAccountName = computed((): string => user.value && user.value.email ? user.value.email : '');
const currentBalance = computed((): number => currentBillingAccount.value && currentBillingAccount.value.credits ? currentBillingAccount.value.credits : 0);
</script>